import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const epkUrl = 'https://1drv.ms/u/s!AkOQeC19V3QFdp9dCSUvvWxWH4g?e=ZnKcG8';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "pic" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
          <div className="LinksPage">
          <Img
            fluid={data.art_ideas.childImageSharp.fluid}
            alt="User Photo"
            className="userPhoto"
          />
          <div id="links">
            <a
              className="link"
              href="https://bit.ly/PreSaveEncuentro"
              target="_blank"
            >
              <i aria-hidden="true" className="fa fa-spotify"></i>
              &#160;&#160;Encuentro (pre save)
            </a>
            <a
              className="link"
              href="https://open.spotify.com/artist/44UGtpVvDidX2nmD3bc8Rb"
              target="_blank"
            >
              <i aria-hidden="true" className="fa fa-youtube"></i>
              &#160;&#160;Spotify
            </a>
          </div>
          </div>
    )}
  />
);

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
