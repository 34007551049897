import React from 'react';
import Layout from '@common/Layout';
import Links from '@sections/Links';
import Footer from '@sections/Footer';
import Navbar from '@common/Navbar';
import { Container } from '@components/global';

const SecondPage = () => (
  <Layout>
    <Navbar />
    <Container>
      <Links />
    </Container>
    <Footer />
  </Layout>
);

export default SecondPage;
